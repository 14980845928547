import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import Section, { Label } from '../../components/Section';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  ContentAnalyticsDrops,
  ContentAnalyticsGrowth,
  ContentAnalyticsExport,
  ContentAnalyticsImpressions,
  FeatureGrid,
  ContentAnalyticsCompare,
  ContentAnalyticsRevisions
} from '../../components/FeatureGrid';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/revenue-attribution/"
          title="Affiliate Revenue Attribution for Publishers"
          description="Optimize your commerce content with CRO tools tailored for affiliates."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Know the value of your commerce content down to the page, link, and
            element-level
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Affilimate provides content publishers with CRO tools tailored for
            affiliates. Attribute revenue to the exact page, link, and element
            that converted &mdash; automated, at scale.
          </Typography>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Get started" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '900px', margin: '96px auto 48px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Revenue attribution down to the pixel
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Go from site and page-level attribution down to the exact link and
            element responsible for your revenue.
          </Typography>
        </div>
        <Section
          image="/images/features/new-page-level-reporting.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Page-level</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                See how much affiliate revenue each page generated,
                automatically
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Attribute commissions to specific pages on your website to
                derive the true value of each piece of content.
              </Typography>
              <Typography paragraph variant="body1">
                Track key metrics like clicks, revenue, RPM, and EPC so you can
                understand which categories and topics perform best.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/new-heatmaps.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Element-level</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Track which buttons, links, or creatives are your real
                money-makers
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Affilimate tells you not only which links earn, but which visual
                representations get your readers to click and convert.
              </Typography>
              <Typography paragraph variant="body1">
                That way you can experiment with confidence (and scale winning
                strategies).
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/multi-site.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Site-level</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Attribute commissions to the correct website in your portfolio
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Separate your affiliate revenue across multiple properties, even
                if they share affiliate links.
              </Typography>
              <Typography paragraph variant="body1">
                Track earnings across up to 50 websites in a single portfolio,
                or divide them amongst multiple workspaces.
              </Typography>
            </>
          }
        />
        <div style={{ textAlign: 'center', margin: '48px auto 96px' }}>
          <SignupButtonWithChoice text="Get started" />
        </div>
        <div style={{ maxWidth: '900px', margin: '96px auto 48px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 900
            }}
          >
            But wait, there's more
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Here are a few more ways you can put the data from your content
            reports to use
          </Typography>
        </div>
        <FeatureGrid>
          <ContentAnalyticsImpressions />
          <ContentAnalyticsGrowth />
          <ContentAnalyticsExport />
          <ContentAnalyticsDrops />
          <ContentAnalyticsRevisions />
          <ContentAnalyticsCompare />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
